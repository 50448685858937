<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols sm="8">
            <v-row>
              <v-col cols>
                <v-stepper
                  v-model="e6"
                  vertical
                  v-show="this.verPedidoRealizado === false"
                >
                  <v-stepper-step
                    class="titulo-stepper"
                    :complete="e6 > 1"
                    step="1"
                  >
                    Configurar paquete
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <fibra />
                    <movil />

                    <v-btn
                      class="configurador-paso-1 botonContinuar"
                      v-if="
                        !datosPortabilidadesValidados ||
                        !datosPortabilidadesValidadosFijo
                      "
                      disabled
                    >
                      Continuar
                    </v-btn>

                    <v-btn
                      class="configurador-paso-1 botonContinuar"
                      v-if="
                        datosPortabilidadesValidados &&
                        datosPortabilidadesValidadosFijo
                      "
                      color="primary"
                      @click="e6 = 2"
                    >
                      Continuar
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-step
                    v-show="e6 === 2"
                    class="titulo-stepper"
                    :complete="e6 > 2"
                    step="2"
                  >
                    Datos personales
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <orderForm />
                    <v-btn
                      class="configurador-paso-2 botonContinuar"
                      v-if="!datosClienteValidados"
                      disabled
                    >
                      Contratar
                    </v-btn>

                    <botonContratar
                      class="configurador-paso-2 botonContinuar"
                      v-show="datosClienteValidados"
                    >
                    </botonContratar>
                  </v-stepper-content>
                </v-stepper>
                <PedidoRealizado v-show="this.verPedidoRealizado === true" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols sm="4">
            <v-row>
              <carrito />
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols sm="8" align="right">
            <v-btn class="botonVolver" @click="volver()">
              <v-icon dark left> mdi-arrow-left </v-icon>Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        fibra: () => import('./components/configurador-fibra.vue'),
        movil: () => import('./components/configurador-móvil.vue'),
        carrito: () => import('./components/configurador-carrito.vue'),
        orderForm: () => import('./components/orderForm.vue'),
        botonContratar: () => import('./components/botonContratar.vue'),
        PedidoRealizado: () => import('./components/pedidoRealizado.vue')
    },
    // e6 son los steps del componente v-stepper-content
    data () {
        return {
            e6: 1,
            datosPortabilidadesValidados: true,
            datosPortabilidadesValidadosFijo: true,
            datosClienteValidados: false,
            verPedidoRealizado: false
        }
    },
    methods: {
        volver () {
            this.$store.dispatch('setpedidoRealizado', false)
            if (this.e6 === 1) {
                this.$store.dispatch('setcarritoPedidosLineasMovil', '')
                this.$store.dispatch('setLineasMovil', [])
                this.$router.push('/verPedidos')
            } else {
                this.e6 = this.e6 - 1
            }
        }
    },
    computed: {
        VdatosPortabilidadesValidados () {
            return this.$store.getters.getdatosPortabilidadesValidados
        },
        VdatosPortabilidadesValidadosFijo () {
            return this.$store.getters.getdatosPortabilidadesValidadosFijo
        },
        VdatosClienteValidados () {
            return this.$store.getters.getdatosClienteValidados
        },
        VPedidoRealizado () {
            return this.$store.getters.getpedidoRealizado
        }
    },
    watch: {
        VdatosPortabilidadesValidados (value) {
            if (value) {
                this.datosPortabilidadesValidados = true
            } else {
                this.datosPortabilidadesValidados = false
            }
        },
        VdatosPortabilidadesValidadosFijo (value) {
            if (value) {
                this.datosPortabilidadesValidadosFijo = true
            } else {
                this.datosPortabilidadesValidadosFijo = false
            }
        },
        VdatosClienteValidados (value) {
            if (value) {
                this.datosClienteValidados = true
            } else {
                this.datosClienteValidados = false
            }
        },
        VPedidoRealizado (value) {
            this.verPedidoRealizado = value
        }
    }
}
</script>
